<template>
  <div class="relative z-1">
    <div
      :id="data.id"
      class="flex items-center pt-1 pb-1 advert"
      v-bind:class="{'md:hidden' : mobileOnly(data.id), 'justify-start' : xAlign === 'left', 'justify-center' : xAlign === 'center', 'justify-end' : xAlign === 'right'}"
    ></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    data: Object,
    xAlign: { type: String, default: "center" }
  },
  methods: {
    ...mapActions(["loadAd"]),
    mobileOnly(str) {
      return str.includes("mobile");
    },
    adsLoadedCallback() {
      this.loadAd(this.data.id);
    }
  },
  beforeMount() {
    this.adsLoadedCallback()
  }
};
</script>

<style lang="scss">
.advert {
  min-height: 60px;
  @screen lg {
    min-height: 100px;
  }
}
</style>